import { Order, Request } from 'types/models';
import { hasOneDayPassed, maxDateOfDates, minDateOfDates } from 'lib/Helpers/DateTimeHelper';
import { ProviderRequestState, RequestState } from 'types/staffing';
import { useAccount } from 'lib/Account';
import { AccountUser, Locale, MemberType } from 'types';
import { useLocalization } from 'lib/Localization';
import { JobState } from 'types/staffing/JobStateMachine';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';
import { userIsClient } from 'lib/Helpers/UserHelper';

type TabInfo = {
    active: number[];
    canceled: number[];
    draft: number[];
    history: number[];
    pending: number[];
};

const getTabInfo = (user: AccountUser) => (order: Request | Order) => {
    const tab: TabInfo = {
        active: [],
        canceled: [],
        draft: [],
        history: [],
        pending: [],
    };

    if (!order.hasOwnProperty('requests')) {
        order = {
            is_draft: (order as Request).state === RequestState.DRAFT,
            requests: [order],
        } as Order;
    }

    order = order as Order;

    if (order.is_draft) {
        tab.draft = order.requests.map(({ id }) => id);

        return tab;
    }

    for (let i = 0; i < order.requests.length; i++) {
        const request = order.requests[i];
        const isProvider = user.type === MemberType.AGENT;
        const isClient = user.type === MemberType.CONTACT;
        const orgId = user?.organization?.id;

        const { jobs, state, provider_requests } = request;

        if (isProvider) {
            const hasOpenPositions = request.jobs.some((job) => {
                return job.state === JobState.OPEN;
            });

            const hasPositionsFilledByCurrentProvider = request.jobs.some((job) => {
                return (
                    job.applicants &&
                    job.applicants?.filter((applicant) => {
                        return applicant.worker.provider.id === user.organization.id;
                    }).length > 0
                );
            });

            if (!hasOpenPositions && !hasPositionsFilledByCurrentProvider) {
                tab.canceled.push(request.id);
                continue;
            }

            const hasProviderRejectedTheRequest = provider_requests?.some(
                (providerRequest) =>
                    providerRequest.state === ProviderRequestState.REJECTED && providerRequest.provider_id === orgId
            );

            if (hasProviderRejectedTheRequest) {
                tab.history.push(request.id);
                continue;
            }
        }

        const dates = jobs.map((job) => new Date(job.date)) as Date[];
        const maxDate = dates.length ? maxDateOfDates(dates) : null;

        const hasPastJobs = jobs.some(
            ({ is_client_archived, is_provider_archived }) =>
                (isClient && is_client_archived) || (isProvider && is_provider_archived)
        );
        const hasFutureJobs = jobs.some(
            ({ is_client_archived, is_provider_archived }) =>
                (isClient && !is_client_archived) || (isProvider && !is_provider_archived)
        );

        const hasProviderAcceptedRequest = provider_requests?.some((providerRequest) =>
            [ProviderRequestState.ACCEPTED, ProviderRequestState.CONCLUDED].includes(providerRequest.state as any)
        );

        if (hasPastJobs) {
            tab.history.push(request.id);
        }

        switch (state) {
            case RequestState.PENDING:
                if (hasFutureJobs) {
                    if (isProvider) {
                        hasProviderAcceptedRequest ? tab.active.push(request.id) : tab.pending.push(request.id);
                    } else if (isClient) {
                        tab.active.push(request.id);
                    }
                }

                break;

            case RequestState.FULFILLED:
            case RequestState.CONCLUDED:
                if (hasFutureJobs) {
                    tab.active.push(request.id);
                }

                break;

            case RequestState.POSTED:
                if (hasFutureJobs) {
                    tab.pending.push(request.id);
                }

                break;

            case RequestState.CANCELED:
                tab.canceled.push(request.id);

                if (!hasPastJobs) {
                    tab.history.push(request.id);
                }

                break;

            case RequestState.EXPIRED:
                if (!hasPastJobs) {
                    tab.history.push(request.id);
                }

                break;

            case RequestState.REJECTED:
                const movableJobs = request.jobs.filter(({ is_client_archived }) => !is_client_archived);

                if (isClient && !!movableJobs.length) {
                    tab.pending.push(request.id);
                } else if ((isClient && !movableJobs.length) || (maxDate && hasOneDayPassed(maxDate) && !hasPastJobs)) {
                    tab.history.push(request.id);
                }

                break;

            default:
                break;
        }
    }

    return tab;
};

const getUpdatedOrder = (locale: Locale, isClient: boolean) => (order: Order) => ({
    ...order,
    requests: order.requests.map((request) => {
        const { jobs, state } = request;

        let dateRange = '-';

        if (state !== RequestState.DRAFT) {
            const dates = jobs.map((job) => new Date(job.date)) as Date[];

            const options: Intl.DateTimeFormatOptions = {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            };

            const minDate = minDateOfDates(dates);
            const maxDate = maxDateOfDates(dates);
            const minLocaleDate = minDate.toLocaleDateString(locale.shortCode, options);
            const maxLocaleDate = maxDate.toLocaleDateString(locale.shortCode, options);

            dateRange = `${minLocaleDate} - ${maxLocaleDate}`;
        }

        const filledPositionsCount = jobs.filter(({ state }) =>
            [JobState.FILLED, JobState.TIME_CONFIRMED, JobState.TIME_REJECTED].includes(state as any)
        ).length;
        const otherProviderAppliedPositionsCount = jobs.filter(
            ({ state, other_provider_applicants }) => state === JobState.OPEN && (other_provider_applicants || 0) > 0
        ).length;
        const currentProviderAppliedPositionsCount = jobs.filter(({ applicants, state, other_provider_applicants }) => {
            const pendingApplicantsLength = (applicants || []).filter(
                ({ state }) => state === JobApplicantState.PROPOSED
            ).length;

            return state === JobState.OPEN && (other_provider_applicants || 0) === 0 && !!pendingApplicantsLength;
        }).length;
        const availableJobsCount = jobs.filter(({ state }) => state !== JobState.CANCELED).length;
        const allFilledPositionsCount =
            filledPositionsCount + otherProviderAppliedPositionsCount + currentProviderAppliedPositionsCount;
        const filledPositionsPercent = Math.round((allFilledPositionsCount / availableJobsCount) * 100);
        const confirmedPositionsPercent = Math.round((filledPositionsCount / availableJobsCount) * 100);

        return {
            ...request,
            client: order.client,
            created_by: order.created_by,
            profession: request.profession ?? {},
            dateRange: dateRange,
            allFilledPositionsCount,
            filledPositionsCount,
            filledPositionsPercent,
            confirmedPositionsPercent,
        };
    }),
});

export const useRequestTab = () => {
    const { accountUser: user } = useAccount();
    const { locale } = useLocalization();
    const isClient = userIsClient(user);

    return {
        getTabInfo: getTabInfo(user),
        getUpdatedOrder: getUpdatedOrder(locale, isClient),
    };
};
