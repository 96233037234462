import { useLocalization } from 'lib/Localization';

type Props = {
    actual?: boolean;
    breakDuration?: string;
    breakStartTime?: string;
    endTime: string;
    shiftDuration?: string;
    startTime: string;
};

export const WorkedInfo = ({ actual, breakDuration, breakStartTime, endTime, shiftDuration, startTime }: Props) => {
    const { t } = useLocalization();

    return (
        <>
            <tr className={actual ? 'actual' : ''}>
                <td className="text-indented">{t('Shift interval')}</td>
                <td></td>
                <td>{t('Break')}</td>
                <td>{t('Start time')}</td>
            </tr>

            <tr className={actual ? 'actual' : ''}>
                <td className="text-indented">
                    {startTime} - {endTime}
                </td>
                <td style={{ paddingLeft: '15px' }}>{shiftDuration}</td>
                <td>{breakDuration}</td>
                <td>{breakStartTime}</td>
            </tr>
        </>
    );
};
