export enum JobState {
    OPEN = 'open',
    FILLED = 'filled',
    TIME_CONFIRMED = 'time_confirmed',
    TIME_REJECTED = 'time_rejected',
    CANCELED = 'cancelled',
}

export enum JobTransition {
    FILL = 'fill',
    CANCEL = 'cancel',
    CONFIRM_TIME = 'confirm_time',
    REJECT_TIME = 'reject_time',
}
