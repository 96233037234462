import { FilterFilled } from '@ant-design/icons';
import { Button, Checkbox, Popover, Space, Switch } from 'antd';
import { t } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { Filters } from 'types/enums/RequestJobsTable';
import { GenericObject } from 'shared/Contracts';

type Props = {
    canToggleWorkedTimes?: boolean;
    filterRequestJobs: (requestJobTableFilters: string[]) => void;
    isClient: boolean;
    showWorkedTimes: boolean;
    setShowWorkedTimes: (state: boolean) => void;
};

export const RequestJobTableFilters = ({
    canToggleWorkedTimes,
    filterRequestJobs,
    isClient,
    showWorkedTimes,
    setShowWorkedTimes,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [requestJobTableFilters, setRequestJobTableFilters] = useState<string[]>([]);
    const [canFilter, setCanFilter] = useState<GenericObject>({
        [Filters.OPEN_SHIFTS]: true,
        [Filters.WORK_TIME_CONFIRMED]: true,
        [Filters.WORK_TIME_PENDING_CONFIRMATION]: true,
        [Filters.FILLED]: true,
        [Filters.I_ASSIGNED]: true,
    });

    const toggleFilters = (filter: string) =>
        setRequestJobTableFilters((prevState) => {
            const state = [...prevState];

            const index = state.indexOf(filter);
            if (index > -1) {
                state.splice(index, 1);
            } else {
                state.push(filter);
            }

            return state;
        });

    const handleOpenChange = (flag: boolean) => {
        setOpen(flag);
    };

    const renderCheckbox = (filterType: string, title: string, style: any) => {
        return (
            <div>
                <Checkbox
                    disabled={!canFilter[filterType]}
                    onClick={() => toggleFilters(filterType)}
                    checked={requestJobTableFilters.includes(filterType)}
                    style={style}
                >
                    {t(title)}
                </Checkbox>
            </div>
        );
    };

    const items = [
        renderCheckbox(Filters.OPEN_SHIFTS, 'Open shifts', {
            margin: '6px 0px',
        }),
        renderCheckbox(Filters.WORK_TIME_CONFIRMED, 'Worked time confirmed', {
            margin: '6px 0px',
        }),
        renderCheckbox(Filters.WORK_TIME_PENDING_CONFIRMATION, 'Worked time pending confirmation', {
            marginTop: '6px',
        }),
    ];

    if (isClient) {
        items.unshift(
            renderCheckbox(Filters.FILLED, 'Filled', {
                marginBottom: '6px',
            })
        );
    } else {
        items.unshift(
            renderCheckbox(Filters.I_ASSIGNED, 'Assigned by me', {
                marginBottom: '6px',
            })
        );
    }

    useEffect(() => {
        if (requestJobTableFilters.includes(Filters.OPEN_SHIFTS)) {
            setCanFilter({
                [Filters.OPEN_SHIFTS]: true,
                [Filters.WORK_TIME_CONFIRMED]: false,
                [Filters.WORK_TIME_PENDING_CONFIRMATION]: false,
                [Filters.FILLED]: false,
                [Filters.I_ASSIGNED]: false,
            });
        } else if (requestJobTableFilters.length > 0) {
            setCanFilter({
                [Filters.OPEN_SHIFTS]: false,
                [Filters.WORK_TIME_CONFIRMED]: !requestJobTableFilters.includes(Filters.WORK_TIME_PENDING_CONFIRMATION),
                [Filters.WORK_TIME_PENDING_CONFIRMATION]: !requestJobTableFilters.includes(Filters.WORK_TIME_CONFIRMED),
                [Filters.FILLED]: true,
                [Filters.I_ASSIGNED]: true,
            });
        } else {
            setCanFilter({
                [Filters.OPEN_SHIFTS]: true,
                [Filters.WORK_TIME_CONFIRMED]: true,
                [Filters.WORK_TIME_PENDING_CONFIRMATION]: true,
                [Filters.FILLED]: true,
                [Filters.I_ASSIGNED]: true,
            });
        }

        filterRequestJobs(requestJobTableFilters);
    }, [requestJobTableFilters]);

    return (
        <Space className="pull-right" style={{ marginBottom: '10px' }}>
            {canToggleWorkedTimes && (
                <>
                    {t('Worked times')}
                    <Switch checked={showWorkedTimes} onChange={(state) => setShowWorkedTimes(state)} />
                </>
            )}

            <Popover
                placement="bottomLeft"
                content={items}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                showArrow={false}
            >
                <Button
                    style={{
                        borderRadius: '2px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    <FilterFilled />
                </Button>
            </Popover>
        </Space>
    );
};
