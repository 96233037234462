import { useState } from 'react';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { Input, InputProps } from 'antd';
import { GenericObject } from 'shared/Contracts';
import { TextAreaProps } from 'antd/lib/input';

type Props = GenericObject & {
    danger?: boolean;
    inputProps?: InputProps | TextAreaProps;
    label: string;
    maxLength?: number;
    onCancel?: () => void;
    onOk: (value: string) => void;
    textarea?: boolean;
};

export const PromptModal = ({
    danger,
    inputProps = {},
    label,
    maxLength,
    onCancel,
    onOk,
    textarea,
    ...otherProps
}: Props) => {
    const [value, setValue] = useState('');

    return (
        <CustomModal
            closable={false}
            okButtonProps={{ danger, disabled: !value.length }}
            onCancel={() => {
                setValue('');
                onCancel?.();
            }}
            onOk={() => onOk(value.trim())}
            {...otherProps}
        >
            <FloatingLabel label={label} required>
                <>
                    {!!textarea && (
                        <Input.TextArea
                            {...(inputProps as TextAreaProps)}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            maxLength={maxLength}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            autoFocus
                        />
                    )}
                    {!textarea && (
                        <Input
                            {...(inputProps as InputProps)}
                            maxLength={maxLength}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            autoFocus
                        />
                    )}
                </>
            </FloatingLabel>
        </CustomModal>
    );
};
